
//
//
// npm run build
//

//import './App.css';
import banner from "./banner_landpage_8b.png";
import logo from "./logo_novo_novo.png";
import React, {useEffect} from "react";

function App() {
    useEffect(() => {document.body.style.backgroundColor = '#F7E5CD'}, [])

  return (
      <div className="main" style={{background: '#F7E5CD', width: '100%', height: '100%'}}>
          <center>
              <div className="logo" style={{background: '#F7E5CD', marginBottom: '10px'}}>
                  <img src={banner} className="banner" alt="banner" width={'60%'}
                       style={{marginTop: '5px'}}/>
              </div>

              <div className="tit" style={{background: '#F7E5CD', width: '80%', marginBottom: '10px'}}>
                  {/*<span style={{*/}
                  {/*    // textDecoration: 'line-through',*/}
                  {/*    fontFamily: 'Arial',*/}
                  {/*    fontSize: 20,*/}
                  {/*    color: '#310505'*/}
                  {/*}}>*/}
                  {/*  {'Sistema eCardápios'}*/}
                  {/*</span>*/}
                  <div className="logo" style={{background: '#F7E5CD', marginBottom: '10px'}}>
                      <img src={logo} className="logo" alt="logo" width={'50%'}
                           style={{marginTop: '5px'}}/>
                  </div>
              </div>

              <div className="info1" style={{background: '#F7E5CD', width: '80%', marginBottom: '10px'}}>
                  <span style={{
                      // textDecoration: 'line-through',
                      fontFamily: 'Arial',
                      fontSize: 18,
                      color: '#310505'
                  }}>
                    {'Nosso sistema coloca o Seu Cardápio na tela dos celulares dos Seus Clientes de maneira inteligente e funcional!'}
                  </span>
              </div>

              <div className="info1" style={{background: '#F7E5CD', width: '80%', marginBottom: '10px'}}>
                  <span style={{
                      // textDecoration: 'line-through',
                      fontFamily: 'Arial',
                      fontSize: 16,
                      color: '#310505'
                  }}>
                    {'Diferente de tudo que você já viu por aí!'}
                  </span>

                  <div className="info2" style={{background: '#F7E5CD'}}>
                      <span style={{
                          // textDecoration: 'line-through',
                          fontFamily: 'Arial',
                          fontSize: 16,
                          color: '#310505'
                      }}>
                        {'Nosso sistema Funciona!'}
                      </span>
                  </div>
              </div>

              <div className="info1" style={{background: '#F7E5CD', width: '80%', marginBottom: '10px'}}>
                  <span style={{
                      // textDecoration: 'line-through',
                      fontFamily: 'Arial',
                      fontSize: 16,
                      color: '#310505'
                  }}>
                    {'São três configurações possíveis:'}
                  </span>

                  <div className="info2" style={{background: '#F7E5CD'}}>
                      <span style={{
                          // textDecoration: 'line-through',
                          fontFamily: 'Arial',
                          fontSize: 16,
                          color: '#310505'
                      }}>
                        {'Somente o Cardápio'}
                      </span>
                  </div>

                  <div className="info2" style={{background: '#F7E5CD'}}>
                      <span style={{
                          // textDecoration: 'line-through',
                          fontFamily: 'Arial',
                          fontSize: 16,
                          color: '#310505'
                      }}>
                        {'Cardápio com Pedidos'}
                      </span>
                  </div>

                  <div className="info2" style={{background: '#F7E5CD'}}>
                      <span style={{
                          // textDecoration: 'line-through',
                          fontFamily: 'Arial',
                          fontSize: 16,
                          color: '#310505'
                      }}>
                        {'Cardápio com Pedidos + KDS (Módulo da Cozinha)'}
                      </span>
                  </div>
              </div>
          </center>
      </div>
  );
}

export default App;
